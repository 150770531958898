import React, { useRef, useContext, useState, useEffect, useLayoutEffect, useCallback } from 'react'

import $ from 'jquery'

import { ColsContext } from 'src/hooks/cols/context'

import Item from 'src/components/news/item'

import { get } from 'src/lib/api'

import styles from './slider.module.scss'

const defaultNews = [0, 1, 2].map(() => { return {} })


const NewsSlider = ({ filter }) => {

    const scroller = useRef()
    const wrapper = useRef()

    const { cols } = useContext(ColsContext)

    const [nodes, setNodes] = useState(defaultNews)
    const [prevHidden, setPrevHidden] = useState(true)
    const [nextHidden, setNextHidden] = useState(false)


    useEffect(() => {
        get(`/app/data/news/${filter}.json`).then((data) => {
            setNodes(data.nodes)
        })
    }, [filter])


    useLayoutEffect(() => {
        if (cols !== 'line_sp' && nodes.length > 0) {

            const items = $(wrapper.current).children('li').toArray()

            function resize() {
                wrapper.current.style.width = items.reduce((w, li) => w + $(li).outerWidth(true), 0) + 'px'
                wrapper.current.style.padding = `0 ${Math.max(60, (document.body.clientWidth - 1160) / 2)}px`
                scroller.current.scrollLeft = 0
                setNextHidden(scroller.current.clientWidth > wrapper.current.clientWidth)
            }

            $(scroller.current).off('scroll').on('scroll', function() {
                const x = scroller.current.scrollLeft
                setPrevHidden(x <= 0)
                setNextHidden(x >= wrapper.current.clientWidth - scroller.current.clientWidth)
            })

            resize()

            window.addEventListener('resize', resize)

            return () => {
                window.removeEventListener('resize', resize)
            }
        } else {
            wrapper.current.style.width = ``
            wrapper.current.style.padding = ``
        }
    }, [cols, nodes])


    const scrollTo = useCallback((index) => {
        const itemWidth = $(wrapper.current).children('li').eq(0).outerWidth(true)
        const x = Math.min(index * itemWidth, wrapper.current.clientWidth - scroller.current.clientWidth)
        $(scroller.current).stop().animate({ scrollLeft: x }, 600, 'ioX4')
    }, [])

    const prev = useCallback(() => {
        const itemWidth = $(wrapper.current).children('li').eq(0).outerWidth(true)
        const current = Math.ceil(scroller.current.scrollLeft / itemWidth) - 1
        scrollTo(current)
    }, [scrollTo])

    const next = useCallback(() => {
        const itemWidth = $(wrapper.current).children('li').eq(0).outerWidth(true)
        const current = Math.floor(scroller.current.scrollLeft / itemWidth) + 1
        scrollTo(current)
    }, [scrollTo])


    return (
        <div className={styles.slider}>
            <div className={styles.scroller} ref={scroller}>
                <ul ref={wrapper}>
                    {nodes.slice(0, cols === 'line_sp' ? 3 : 6).map((node, i) => <li key={i}><Item {...node} /></li>)}
                </ul>
            </div>
            <button className={styles.prev} onClick={() => prev()} aria-hidden={prevHidden}>prev</button>
            <button className={styles.next} onClick={() => next()} aria-hidden={nextHidden}>next</button>
        </div>
    )
}

export default NewsSlider