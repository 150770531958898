import React, { useState, useEffect } from 'react'

import SEO from 'src/components/seo/index'
import Header from 'src/components/header/setting'
import Mainview from 'src/components/mainview/index'
import Title from 'src/components/title/index'
import Inner from 'src/components/inner/index'
import Link from 'src/components/link/index'
import CampaignButton from 'src/components/campaign/button'
import AdmissionButton from 'src/components/admission/button'
import Slider from 'src/components/news/slider'
import QandA from 'src/components/qanda/index'
import Prices from 'src/components/prices/index'
import ReadyToGo from 'src/components/readytogo/index'

import { get } from 'src/lib/api'

import styles from './index.module.scss'

const BeginnerPage = () => {
	const [items, setItems] = useState([])

	useEffect(() => {
		if (typeof window !== `undefined`) {
			get(`/app/data/faq/beginner.json`).then(setItems)
		}
	}, [setItems])

	return (
		<div className={styles.beginners}>
			<SEO title="FOR BEGINNER" />
			<Header hasBack={false} />

			<Mainview className={styles.mainview}>
				<div className={styles.mainimage}>
					<Inner className={styles.title}>
						<h1>FOR BEGINNER</h1>
					</Inner>
				</div>
			</Mainview>

			<section id="supports" className={styles.supports}>
				<div className={styles.aboutContent}>
					<div>
						<div className={styles.aboutLeft}>
							<h2 className={styles.aboutLogo}>
								<div className={styles.placer}>
									<img src="/img/top/logo.svg" alt="WE ARE THE FIT" />
								</div>
							</h2>
							<div className={styles.aboutButtons}>
								<AdmissionButton className={styles.forAdmission} />
								<CampaignButton className={styles.forCampaign} />
							</div>
						</div>
						<div className={styles.aboutRight}>
							<div className={styles.aboutText}>
								<p>
									ワークアウトを始めてみたい。
									<br />
									でもたくさんのマシーンを
									<br className={styles.spOnly} />
									目の前にしたとたんに
									<br />
									どうすればよいかが分からない。
									<br />
									目標やモチベーションと同じくらい
									<br />
									不安も持っている方も
									<br className={styles.spOnly} />
									多いのではないでしょうか。
								</p>
								<p>
									でも、ご安心ください。
									<br />
									<em>WE ARE THE FIT</em>はそんな方々のために
									<br />
									周りに気兼ねなく安心できる
									<br className={styles.spOnly} />
									ビギナー向けフロアや
									<br />
									経験豊富なスタッフによる
									<br className={styles.spOnly} />
									オリエンテーションなど
									<br />
									しっかりとしたサポートが充実しています。
								</p>
							</div>
						</div>
					</div>
				</div>
				<ol className={styles.supportsList}>
					<li>
						<div className={styles.supportsImage}>
							<div className={styles.placer}>
								<img src="/img/beginners/support01.jpg" alt="" />
							</div>
						</div>
						<div className={styles.supportsBlock}>
							<div>
								<h3>SUPPORT FOR BEGINNER 01</h3>
								<div>
									<strong>ビギナー向けワークアウトフロア</strong>
									<p>
										ワークアウトに慣れた人たちの中だと、初心者にとっては気兼ねをしてしまうことも。なので、同じレベルの人たちが利用する初心者向けフロアをご用意しております。
										<br />
										まずはこのフロアから始めて、レベルアップしたい時は上級フロアへ挑戦してみてください。
									</p>
								</div>
							</div>
						</div>
					</li>
					<li>
						<div className={styles.supportsImage}>
							<div className={styles.placer}>
								<img src="/img/beginners/support02.jpg" alt="" />
							</div>
						</div>
						<div className={styles.supportsBlock}>
							<div>
								<h3>SUPPORT FOR BEGINNER 02</h3>
								<div>
									<strong>初回カウンセリングとオリエンテーション</strong>
									<p>
										ご希望の方には、初回オリエンテーションを実施しております。スタッフが施設案内・マシーンの使い方の説明・簡単なカウンセリングを行います。
										<br />
										初回でなくても、不明点・ご質問などあればいつでもスタッフにお声がけください。
									</p>
								</div>
							</div>
						</div>
					</li>
					<li>
						<div className={styles.supportsImage}>
							<div className={styles.placer}>
								<img src="/img/beginners/support03.jpg" alt="" />
							</div>
						</div>
						<div className={styles.supportsBlock}>
							<div>
								<h3>SUPPORT FOR BEGINNER 03</h3>
								<div>
									<strong>ステップアップサポート</strong>
									<p>
										マシーンの使い方がわからなくなったり、ワークアウトで気になる事があればスタッフまで気軽にお声掛け下さい。初心者向けのトレーニングメニューもご用意しております。もちろん無料ですのでご安心を。より本格的な指導を受けたい方はパーソナルトレーナー（有料）のご紹介も可能です。
									</p>
								</div>
							</div>
						</div>
					</li>
				</ol>
			</section>

			<section id="trial" className={styles.trial}>
				<Title
					h2="TRIAL"
					sub={
						<div>
							入会を悩んでいる方。話しを聞いて申し込みを決めたい方。
							<br />
							ぜひ、見学に<em>WE ARE THE FIT</em>へお越しください！
						</div>
					}
				/>
				<Inner>
					<ol className={styles.steps}>
						<li>
							<div className={styles.stepHead}>
								<span className={styles.stepText}>STEP</span>
								<span className={styles.stepNum}>1</span>
							</div>
							<div className={styles.stepBody}>
								<h3>ご来店</h3>
								<div>
									<p>
										スタッフ在中時間帯（11:00〜15:00、16:00〜20:00）にご来店し、フロントスタッフに見学希望の旨をお伝えください。
										<br />
										はじめにスタッフよりご利用案内をいたします。不安なことや不明点などあればなんでも聞いてください。その後、見学となります。
									</p>
								</div>
							</div>
						</li>
						<li>
							<div className={styles.stepHead}>
								<span className={styles.stepText}>STEP</span>
								<span className={styles.stepNum}>2</span>
							</div>
							<div className={styles.stepBody}>
								<h3>見学</h3>
								<div>
									<p>
										入館から、ロッカールーム、ワークアウトフロアとスタッフがご案内いたします。
										<br />
										マシンの使用方法が分からない方は、スタッフが実演しながらご説明いたしますのでご安心を。
									</p>
								</div>
							</div>
						</li>
						<li>
							<div className={styles.stepHead}>
								<span className={styles.stepText}>STEP</span>
								<span className={styles.stepNum}>3</span>
							</div>
							<div className={styles.stepBody}>
								<h3>ご希望の方へ入会案内</h3>
								<div>
									<p>見学をしてみて、続けたいと思われた方はその場で入会お手続きが可能です。</p>
								</div>
							</div>
						</li>
					</ol>
					<div className={styles.attention}>
						<p>見学はお一人さま１回限りとさせていただいております。</p>
					</div>
				</Inner>
			</section>

			<section id="admission" className={styles.admission}>
				<Title
					h2="ADMISSION"
					sub={
						<div>
							<em>WE ARE THE FIT</em>への入会方法をご説明いたします。
							<br />
							<em>WEB</em>でのお申し込みですので、いつでも、どこからでも申し込みが可能です。
						</div>
					}
				/>
				<Inner>
					<ol className={styles.steps}>
						<li>
							<div className={styles.stepHead}>
								<span className={styles.stepText}>STEP</span>
								<span className={styles.stepNum}>1</span>
							</div>
							<div className={styles.stepBody}>
								<h3>WEBより入会申し込み</h3>
								<div>
									<p>
										以下の入会申し込みボタンをクリックするとフォームが開きますので、サービス内容の選択やお客さまの情報のご入力、利用開始日のご指定等をお願いいたします。
										<br />
										WEBお申し込み完了後は、登録されたメールアドレスにご確認のメールをお送りいたしますのでご確認ください。
									</p>
									<AdmissionButton className={styles.stepButton} />
								</div>
							</div>
						</li>
						<li>
							<div className={styles.stepHead}>
								<span className={styles.stepText}>STEP</span>
								<span className={styles.stepNum}>2</span>
							</div>
							<div className={styles.stepBody}>
								<h3>ご来店</h3>
								<div>
									<p>
										翌日以降のスタッフ在中時間帯（11:00〜15:00、16:00〜20:00）で、お客さまのご都合の良いタイミングに[
										ご本人さま確認証明書 ]をご持参の上、ご来店ください。
										<br />
										以降の入館時に必要なセキュリティカードをお渡しいたします。
										<br />
										ご来店時にはお客さまの顔写真を撮影させていただいておりますのでご了承ください。
									</p>
								</div>
							</div>
						</li>
						<li>
							<div className={styles.stepHead}>
								<span className={styles.stepText}>STEP</span>
								<span className={styles.stepNum}>3</span>
							</div>
							<div className={styles.stepBody}>
								<h3>ご利用開始</h3>
								<div>
									<p>
										お手続きはこれで完了です。
										<br />
										お申し込み時にご指定いただいた開始日以降、いつでも、時間制限なく、ご利用いただけます。
										<br />
										お越しの際はくれぐれもセキュリティカードをお忘れなく！
									</p>
								</div>
							</div>
						</li>
					</ol>
				</Inner>
			</section>

			<section id="faq" className={styles.faq}>
				<Title
					h2="FAQ"
					right={
						<ul className={styles.faqTypes}>
							{items.map((group, i) => (
								<li key={i}>
									<Link to={`/beginners/#${group.slug}`}>{group.type}</Link>
								</li>
							))}
						</ul>
					}
				/>
				<Inner className={styles.faqBody}>
					<div className={styles.faqList}>
						{items.map((group, i) => (
							<div key={i} id={group.slug}>
								<h3>{group.type}</h3>
								<ul>
									{group.nodes.map((node, j) => (
										<li key={j}>
											<QandA q={node.q} a={node.a} />
										</li>
									))}
								</ul>
							</div>
						))}
					</div>
					<div className={styles.attention}>
						<p>
							上記の内容以外にサポートが必要な場合は<Link to="/faq/">FAQ</Link>もあわせてご確認ください。
						</p>
					</div>
				</Inner>
			</section>

			<section id="price" className={styles.price}>
				<div>
					<Title h2="PRICE" />
					<Inner>
						<Prices />
					</Inner>
				</div>
			</section>

			<section id="campaign" className={styles.campaign}>
				<Title
					h2="CAMPAIGN"
					right={
						<Link className={styles.newsLink} to="/news/">
							View All News
						</Link>
					}
				/>
				<div className={styles.newsList}>
					<Slider filter="campaign" />
				</div>
				<div className={styles.newsAll}>
					<Link to="/news/">View All News</Link>
				</div>
			</section>

			<ReadyToGo />
		</div>
	)
}

export default BeginnerPage
