

import React, { useRef, useState } from 'react'

import $ from 'jquery'

import styles from './index.module.scss'


const QandA = ({ q, a }) => {

    const answer = useRef()

    const [ expanded, setExpanded ] = useState(false)

    const toggle = () => {
        if (expanded) {
            setExpanded(false)
            $(answer.current).stop().slideUp(300)
        } else {
            setExpanded(true)
            $(answer.current).stop().slideDown(300)
        }
    }

    return (
        <div className={styles.qanda}>
            <button className={styles.question} onClick={() => toggle()} aria-expanded={expanded}>
                <div>
                    <div className={styles.flex}><p dangerouslySetInnerHTML={{ __html: q }}></p></div>
                </div>
            </button>
            <div ref={answer} className={styles.answer}>
                <div>
                    <div className={styles.flex}><p dangerouslySetInnerHTML={{ __html: a }}></p></div>
                </div>
            </div>
        </div>
    )
}

export default QandA